//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axioserror';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  succMsg: null,
  errMsg: null,
};

const slice = createSlice({
  name: 'submitError',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.data = [];
      state.succMsg = null;
      state.errMsg = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload.message;
      state.data = [];
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.succMsg = action.payload.message;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function submitReportError(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/error', data, {
        headers: {
          ContentType: 'multipart/form-data',
        },
      });
      if (response?.data?.status === 1) {
        dispatch(slice.actions.createEventSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
