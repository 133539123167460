//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axioserror';
//
import { dispatch } from '../store';
import moment from 'moment';
import { submitReportError } from './submitError';
import { HOST_API } from 'src/config';
import { getDeviceType, getUserLocation } from 'src/utils/getUserDetails';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  succMsg: null,
  errMsg: null,
};

const slice = createSlice({
  name: 'reportForm',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.data = [];
      state.succMsg = null;
      state.errMsg = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload.message;
      state.data = [];
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.succMsg = action.payload.message;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function submitReportForm(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/inspection-report', data, {
        headers: {
          ContentType: 'multipart/form-data',
        },
      });
      if (response?.data?.status === 1) {
        dispatch(slice.actions.createEventSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {

      function formDataToJson(formData) {
        const jsonObject = {};
        formData.forEach((value, key) => {
          if (jsonObject[key]) {
            jsonObject[key] = [].concat(jsonObject[key], value);
          } else {
            jsonObject[key] = value;
          }
        });
        return jsonObject;
      }

      const clientInfo = {
        device: getDeviceType(),
        location: await getUserLocation()
      }

      const formData = new FormData();
      formData.append('method', "POST");
      formData.append('payload', JSON.stringify(formDataToJson(data)));
      formData.append('response_code', error?.status);
      formData.append('response_body', JSON.stringify(error?.data));
      formData.append('error_type', error?.statusText);
      formData.append('error_occurred_at', moment().format('YYYY-MM-DD HH:mm:ss'));
      formData.append('url', `${HOST_API}api/v1/inspection-report`);
      formData.append('app_version', "1.0.0");
      formData.append('client_info', JSON.stringify(clientInfo));

      dispatch(submitReportError(formData));
      dispatch(slice.actions.hasError(error?.data));
    }
  };
}
