import { isMobile, isTablet, browserName, osName, deviceType, mobileModel } from "react-device-detect";

export function getDeviceType() {
    if (isMobile) {
        return {
            isMobile: true,
            osName,
            browserName,
            deviceType,
            mobileModel
        };
    } else if (isTablet) {
        return {
            isTablet: true,
            osName,
            browserName,
            deviceType
        };
    } else {
        return {
            isDesktop: true,
            osName,
            browserName,
            deviceType
        };
    }
}

export function getUserLocation() {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    reject("Error getting location: " + error.message);
                }
            );
        } else {
            reject("Geolocation is not supported by this browser.");
        }
    });
}